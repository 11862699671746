<template>
   <svg width="270" height="73" viewBox="0 0 270 73" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M132.962 35.7595C132.962 30.1981 130.986 25.4337 127.052 21.4992C123.117 17.5648 118.353 15.5893 112.791 15.5893C107.213 15.5893 102.465 17.5648 98.5302 21.4992C94.5957 25.4337 92.6201 30.1981 92.6201 35.7595V71.0697H77.4794V35.7595C77.4794 25.9815 80.916 17.6644 87.8056 10.775C94.6953 3.88562 103.013 0.449219 112.791 0.449219C122.569 0.449219 130.887 3.88562 137.776 10.775C138.772 11.7711 139.702 12.8003 140.532 13.8462C141.379 12.8003 142.292 11.7711 143.288 10.775C150.178 3.88562 158.495 0.449219 168.274 0.449219C178.052 0.449219 186.369 3.88562 193.259 10.775C200.149 17.6644 203.585 25.9815 203.585 35.7595V71.0697H188.444V35.7595C188.444 30.1981 186.469 25.4337 182.534 21.4992C178.6 17.5648 173.835 15.5893 168.274 15.5893C162.695 15.5893 157.947 17.5648 154.013 21.4992C150.078 25.4337 148.103 30.1981 148.103 35.7595V71.0697H132.962V35.7595V35.7595Z"
         fill="#137AF6"
      />
      <path
         d="M269.127 60.7434C262.237 67.6328 253.92 71.0692 244.141 71.0692C234.363 71.0692 226.046 67.6328 219.156 60.7434C212.266 53.854 208.83 45.5369 208.83 35.759C208.83 25.981 212.266 17.6639 219.156 10.7745C226.046 3.88513 234.363 0.44873 244.141 0.44873C253.92 0.44873 262.237 3.88513 269.127 10.7745L269.21 10.8575L258.485 21.5817L258.402 21.4987C254.468 17.5643 249.703 15.5888 244.141 15.5888C238.563 15.5888 233.815 17.5643 229.881 21.4987C225.946 25.4332 223.97 30.1976 223.97 35.759C223.97 41.3369 225.946 46.0848 229.881 50.0192C233.815 53.9536 238.58 55.9291 244.141 55.9291C249.703 55.9291 254.468 53.9536 258.402 50.0192L258.485 49.9362L269.21 60.6604L269.127 60.7434Z"
         fill="#137AF6"
      />
      <path
         d="M70.4405 4.25042V71.0859H54.4366V37.7346C54.4366 32.4056 52.5606 27.857 48.8253 24.1218C45.0899 20.3866 40.5577 18.5106 35.212 18.5106C32.6387 18.5106 30.1651 19.0087 27.8077 19.9715C25.4502 20.951 23.375 22.3289 21.5987 24.1052C17.8633 27.8404 15.9873 32.3724 15.9873 37.7179C15.9873 42.9639 17.8633 47.4627 21.5987 51.1979C23.375 53.0738 25.4502 54.4683 27.8077 55.398C30.1651 56.3276 32.6387 56.7924 35.212 56.7924C37.8848 56.7924 40.2755 56.3442 42.4171 55.4644L48.1612 69.7412C44.0606 71.7831 39.3126 72.8124 33.8838 72.8124C28.9864 72.8124 24.4707 71.8993 20.3369 70.0732C16.2032 68.2471 12.6172 65.7404 9.59572 62.5364C6.57423 59.3324 4.2168 55.5972 2.52344 51.3307C0.846681 47.0809 0 42.5322 0 37.7346C0 32.8373 0.846681 28.2554 2.54004 23.989C4.23341 19.7225 6.59083 15.9873 9.61232 12.7833C12.6338 9.57933 16.2198 7.07259 20.3535 5.24648C24.4873 3.42038 29.003 2.50732 33.9004 2.50732C38.2501 2.50732 42.1182 3.17136 45.505 4.51604C48.8917 5.84412 51.8634 7.53741 54.4366 9.57933V4.25042H70.4405Z"
         fill="#137AF6"
      />
   </svg>
</template>

<script>
export default {
   name: 'IconAmcLoading',
};
</script>

<style scoped></style>
