<template>
   <AmcText class="loading d-flex justify-content-center">
      <!--      <div class="spinner-grow align-self-center" style="width: 5rem; height: 5rem" role="status">
         &lt;!&ndash;         <IconAmcBig />&ndash;&gt;
      </div>-->
      <div class="align-self-center d-flex flex-column">
         <IconAmcLoading />
         <div class="load-bar d-block mt-3">
            <div class="load-content"></div>
         </div>
      </div>
   </AmcText>
</template>

<script>
import IconAmcLoading from '@/components/icons/IconAmcLoading';

export default {
   name: 'AmcLoading',
   components: {
      IconAmcLoading,
   },
};
</script>

<style scoped lang="scss">
.loading {
   width: 100%;
   height: 100vh;
   z-index: 99999;
   background-color: rgba(135, 135, 135, 0.5);
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
}
.load-content {
   width: 70px;
   height: 10px;
   border-radius: 50px;
   background-color: #529bf3;
   position: relative;
   animation-name: loading;
   animation-duration: 2s;
   animation-iteration-count: infinite;
}

@keyframes loading {
   0% {
      left: 0px;
      right: 200px;
   }
   25% {
      left: 200px;
      right: 0px;
   }
   50% {
      left: 0px;
      right: 200px;
   }
   75% {
      left: 200px;
      right: 0;
   }
   100% {
      left: 0px;
      right: 200px;
   }
}
</style>
